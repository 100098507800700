import React, { useEffect } from 'react';
import { Backdrop } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import paleta from '../../configuraciones/paleta';
import styles from './styles';

const Spinner = () => {
  const classes = styles();
  const ajax = useSelector(({ ajax }) => ajax);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (ajax > 0 && (event.key === 'Enter' || event.key === ' ')) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
    if (ajax > 0) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [ajax]);

  return (
    <Backdrop
      className={clsx(classes.root)}
      open={ajax > 0}
      transitionDuration={400}
    >
      <BeatLoader
        loading={ajax > 0}
        color={paleta.bar.loading}
        size={32}
        speedMultiplier={1.1}
      />
    </Backdrop>
  );
};

export default Spinner;
