import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999999999,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

export default styles;
